import * as React from "react";

import Pagination from "@elements/Pagination";
import CaseStudiesStack from "@sections/CaseStudiesStack";
import { getCaseStudiesList } from "@utils";
import { graphql } from "gatsby";

import '../styles/pages/case-studies.css';

type CaseStudyOverviewProps = {
  data: {
    allCaseStudies: {
      edges: Array<{
        node: {
          contentful_id: string;
          title: string;
          slug?: string;
          topSection?;
        }
      }>
    };
    page: {
      id: string;
    };
  }
};

const CaseStudyOverview: React.FC<CaseStudyOverviewProps> = ({ data }) => {
  const [pageNumber, setPageNumber] = React.useState(1);
  const allCaseStudies = getCaseStudiesList(data.allCaseStudies)

  const handleChange = ({ numPage }) => {
    setPageNumber(numPage);
  }

  return (
    <>
      <div className="case-studies--top-section">
        <hr />
        <h1>Case Studies</h1>
      </div>
      <CaseStudiesStack caseStudies={allCaseStudies[pageNumber - 1]} />
      <Pagination
        currentPage={pageNumber}
        handleClick={handleChange}
        paginationId={data.page.id}
        totalPage={allCaseStudies.length}
      />
    </>
  )
};

export default CaseStudyOverview;

export const queryCaseStudyOverview = graphql`
  query QUERY_CASE_STUDIES_INDEX {
    navigation: contentfulNavigationNavMenu(
      contentful_id: { eq: "28QWR4Rx641pUxsQaVNB1m" }
    ) {
      ...NavMenu
    }
    seo: contentfulPage(slug: { eq: "case-studies" }) {
      ...PageSeo
    }

    page: contentfulPage(slug: { eq: "case-studies" }) {
      id
    }

    allCaseStudies: allContentfulCaseStudy(
      filter: { slug: { regex: "/^((?!placeholder-not-erase).)*$/" } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...CaseStudy
        }
      }
    }
  }
`;
