import React from 'react';

import Button from '@elements/Button';
import RichText from '@elements/RichText';
import LogoWhiteSvg from '@svg/logos/reptrak-white.svg';
import { getClasses } from '@utils';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './style.css';

type CardCaseStudyType = {
  className?: string;
  caseStudy: {
    id: string;
    title: string;
    slug?: string;
    alt?: string;
    useFilter?: boolean;
    content?: string;
    image?;
  }
};

const CardCaseStudy = ({ className, caseStudy }: CardCaseStudyType): JSX.Element => {
  const { alt, image, content, useFilter, title, slug } = caseStudy;
  const bacgroundClasses = getClasses(
    `card-case-study__background`,
    !image && `card-case-study__background--empty`,
    (!image || useFilter) && 'card-case-study__background--use-filter',
  );

  return (
    <li className={getClasses(className, 'card-case-study--main')}>
      <div className={bacgroundClasses}>
        {image ? (
          <GatsbyImage alt={alt} image={getImage(image)}/>
        ): (
          <LogoWhiteSvg />
        )}
      </div>
      <div className="card-case-study__body">
        {content && <RichText bodyRichText={content} />}
        {!content && title && (
          <>
            <p><i><b>CASE STUDY</b></i></p>
            <h1>{title}</h1>
          </>
        )}
        {slug && (
          <Button childClassName='btn--green-black' href={`/case-studies/${slug}`}>
            Read case study
          </Button>
        )}
      </div>
    </li>
  );
};

export default CardCaseStudy;
