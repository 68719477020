import React from 'react';

import CardCaseStudy from '@elements/CardCaseStudy';
import './style.css';

type CaseStudiesStackProps = {
  caseStudies: Array<{
    id: string;
    title: string;
    slug?: string;
    alt?: string;
    useFilter?: boolean;
    content?: string;
    image?;
  }>
};

const CaseStudiesStack = ({ caseStudies }: CaseStudiesStackProps): JSX.Element => (
  <section className='case-studies-stack'>
    <ul className='case-studies-stack__container'>
      {caseStudies?.map((oneCaseStudy) => <CardCaseStudy key={oneCaseStudy.id} caseStudy={oneCaseStudy} />)}
    </ul>
  </section>
);

export default CaseStudiesStack;
